<template>
  <div class="row px-4 py-4">
    <div
      v-for="branch in branchs"
      :key="branch._id"
      class="col-xs-12 col-lg-6 col-xl-4 col-xxl-3 pb-4"
    >
      <router-link :to="prefixURL + '/' + branch._id + suffixURL">
        <div class="card h-100">
          <img
            v-bind:src="branch.bannerRoute"
            class="card-img-top"
            v-bind:alt="'oficina ' + branch.cityName"
          />
          <div class="card-body pt-0">
            <h5 class="card-title text-center fw-bold mb-0 text-capitalize">
              {{ branch.cityName }}
            </h5>
            <p class="card-text text-center text-capitalize">
              {{ branch.stateName }}
            </p>
            <p class="card-text endText">{{ branch.description }}</p>
            <div class="d-flex flex-row customCount">
              <div class="flex-fill text-center">
                <p>Usuarios</p>
                <h4 class="fw-bold">0</h4>
              </div>
              <div class="flex-fill text-center">
                <p>Archivos</p>
                <h4 class="fw-bold">{{ branch.fileCount }}</h4>
              </div>
              <div class="flex-fill text-center">
                <p>Empleados</p>
                <h4 class="fw-bold">{{ branch.employeeCount }}</h4>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-xs-12 col-lg-6 col-xl-4 col-xxl-3 pb-4">
      <router-link v-if="addBranch === 'true'" to="/branch/create">
        <div class="card h-100 position-relative">
          <svg
            class="bi position-absolute top-50 start-50 translate-middle"
            id="addBranch"
            fill="currentColor"
          >
            <use xlink:href="/img/icons/bootstrap-icons.svg#plus-circle" />
          </svg>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import Storage from "@/modules/storage";
import employee from "@/router/employee";

export default {
  data() {
    return {
      branchs: []
    };
  },
  props: ["addBranch", "actionPrefix", "actionSuffix"],
  computed: {
    prefixURL() {
      if (this.actionPrefix !== undefined) {
        const URL = window.location.pathname + this.actionPrefix;
        return URL;
      }
      return "";
    },
    suffixURL() {
      if (this.actionSuffix) {
        return this.actionSuffix;
      }
      return "";
    }
  },
  mounted() {
    const store = new Storage();
    store.getData("branch").then(data => {
      if (data.length === 1 && this.addBranch == "false") {
        location.href = this.prefixURL + "/" + data[0]._id + this.suffixURL;
      }
      data.forEach(branch => {
        if (branch.bannerRoute == null) {
          branch.bannerRoute = store.staticURL + "/" + "img/default/branch.jpg";
        } else {
          branch.bannerRoute =
            branch.bannerRoute +
            "?seed=" +
            Math.floor(Math.random() * 10000000);
        }
      });
      this.branchs = data;
      data.forEach((branch, index) => {
        store.getData("state", branch.state).then(state => {
          this.branchs[index].stateName = state.name;
        });
      });
      data.forEach((branch, index) => {
        store.getData("city", branch.city).then(city => {
          this.branchs[index].cityName = city.name;
        });
      });
      data.forEach((branch, index) => {
        this.branchs[index].employeeCount = 0;
        store.getData("employee").then(employeeData => {
          employeeData.forEach(employee => {
            if (employee.branch === branch._id) {
              this.branchs[index].employeeCount++;
            }
          });
        });
        this.branchs[index].fileCount = 0;
        store.getData("file").then(fileData => {
          fileData.forEach(file => {
            if (file.branch === branch._id && file.extension !== "FOLDER") {
              this.branchs[index].fileCount++;
            }
          });
        });
      });
    });
  },
  name: "branchCards"
};
</script>

<style lang="css" scoped>
a {
  text-decoration: none;
  color: var(--main-text);
}
.card {
  min-height: 350px;
  cursor: pointer;
}
.card img {
  clip-path: polygon(0 0, 100% 0%, 100% 80%, 0% 100%);
}
.card > svg {
  width: 100px;
  color: var(--bs-primary);
}
.customCount {
  position: absolute;
  bottom: 10px;
  width: 90%;
}
.endText {
  margin-bottom: 100px;
}
</style>
